<template>
  <div>
    <div v-if="leadQuoteOrder.tasksDue.length" class="callout callout-danger mb-3">
      <div v-if="checkCCFailed">CC failed authorization. Update CC details and authorize manually from CIM (within Auth.NET)</div>
      There are tasks due. <b-link @click.prevent="showTasks">Check</b-link> the tasks section below.
      <div v-if="checkCCTask && !checkCCFailed">Billing Address do not match with either PU or DEL location.</div>
    </div>
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="ml-2 assigned-text">Assigned to: {{ leadQuoteOrder.user ? leadQuoteOrder.user.name : '' }}</div>
        <LabeledData class="ml-2" v-if="tokenCan('orders:view-source') && leadQuoteOrder.source" label="Order Source" :value="leadQuoteOrder.source.name" />
      </div>
      <div>
        <b-dropdown size="sm" variant="primary" text="Internal Status" class="internal-status m-2">
          <b-dropdown-form>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th class="text-right" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tag in leadQuoteOrder.tags" :key="tag.id">
                  <td>{{ tag.name }}</td>
                  <td class="text-right">
                    <b-button @click="tagRemoving = tag.id" size="sm" variant="danger"><i class="fa fa-trash"></i></b-button>
                    <ConfirmationModal
                      v-if="tagRemoving"
                      @confirm="removeTag(tagRemoving)"
                      @cancel="tagRemoving = null"
                      :cancel-label="'No'"
                      :confirm-label="'Yes'"
                      :label="'Are you sure to delete this tag?'"
                    />
                  </td>
                </tr>
                <tr v-if="!leadQuoteOrder.tags.find(o => o.name === 'VIP Customer')">
                  <td>VIP Customer</td>
                  <td class="text-right">
                    <b-button @click="addTag(7)" size="sm" variant="success"><i class="fa fa-plus"></i></b-button>
                  </td>
                </tr>
                <tr v-if="!leadQuoteOrder.tags.find(o => o.name === 'SCAM ALERT')">
                  <td>SCAM ALERT</td>
                  <td class="text-right">
                    <b-button @click="addTag(9)" size="sm" variant="success"><i class="fa fa-plus"></i></b-button>
                  </td>
                </tr>
                <tr v-if="!leadQuoteOrder.tags.find(o => o.name === 'VISIBILITY ALERT')">
                  <td>VISIBILITY ALERT</td>
                  <td class="text-right">
                    <b-button @click="addTag(10)" size="sm" variant="success"><i class="fa fa-plus"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-dropdown-form>
        </b-dropdown>
        <b-dropdown size="sm" variant="primary" :text="followupAt ? 'Follow Up Set for: ' + formattedDate : 'Set a follow up'" class="m-2">
          <div v-if="!followupAt">
            <b-dropdown-item-button @click="handleFollowup(leadQuoteOrder.id, '15m')"> <i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> 15 mins </b-dropdown-item-button>
            <b-dropdown-item-button @click="handleFollowup(leadQuoteOrder.id, '1h')"> <i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> 1 hour </b-dropdown-item-button>
            <b-dropdown-item-button @click="handleFollowup(leadQuoteOrder.id, '1d')"> <i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> 1 day </b-dropdown-item-button>
            <b-dropdown-item-button @click="handleFollowup(leadQuoteOrder.id, 'custom')"> <i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> Custom </b-dropdown-item-button>
          </div>
          <div v-else>
            <b-dropdown-item-button class="has-sub">
              <i class="dropdown-icon mr-1 fa-lg fa fa-plus"></i> Add New Follow Up
              <ul class="dropdown-menu">
                <li>
                  <a @click="handleFollowup(leadQuoteOrder.id, '15m')" class="dropdown-item" href="#"><i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> 15 mins</a>
                </li>
                <li>
                  <a @click="handleFollowup(leadQuoteOrder.id, '1h')" class="dropdown-item" href="#"><i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> 1 hour</a>
                </li>
                <li>
                  <a @click="handleFollowup(leadQuoteOrder.id, '1d')" class="dropdown-item" href="#"><i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> 1 day</a>
                </li>
                <li>
                  <a @click="handleFollowup(leadQuoteOrder.id, 'custom')" class="dropdown-item" href="#"><i class="dropdown-icon mr-1 fa-lg fas fa-clock"></i> Custom</a>
                </li>
              </ul>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="removeFollowup"><i class="dropdown-icon mr-1 fa-lg fas fa-trash"></i> Delete Follow Up</b-dropdown-item-button>
          </div>
        </b-dropdown>
        <DatePickerModal v-if="followupLeadId" @close="followupLeadId = null" @input="val => setFollowup(followupLeadId, val)" />

        <b-btn v-if="canBeReclaimed" :disabled="isReclaimRequested" variant="secondary" @click="clickedReclaim = true" class="btn-sm mr-1">
          <span v-if="isReclaimRequested">Reclaim requested</span>
          <span v-else>Reclaim</span>
        </b-btn>
        <button v-if="tokenCan('admin') && isQuote" class="btn btn-sm btn-secondary mr-1" href="#" @click="reflowQuote">Reflow</button>
        <button v-if="isQuote" class="btn btn-sm btn-warning" href="#" @click="convertToOrder">Convert to order</button>
        <button v-if="showHold" class="btn btn-sm btn-warning ml-1" href="#" @click="onHold">On Hold</button>
        <button v-if="!leadQuoteOrder.deletedAt && leadQuoteOrder.leadStatusId == 4" class="btn btn-sm btn-warning ml-1" href="#" @click="removeHold">Remove Hold</button>
        <button v-if="!leadQuoteOrder.deletedAt && leadQuoteOrder.leadStatusId == 8" class="btn btn-sm btn-warning ml-1" href="#" @click="pickedUp">Picked Up</button>
        <button
          v-if="!leadQuoteOrder.deletedAt && (leadQuoteOrder.leadStatusId == 8 || leadQuoteOrder.leadStatusId == 11)"
          class="btn btn-sm btn-warning ml-1"
          href="#"
          @click="delivered"
        >
          Delivered
        </button>
        <button v-if="!leadQuoteOrder.deletedAt && leadQuoteOrder.leadStatusId == 3 && leadQuoteOrder.signedAt" class="btn btn-sm btn-warning ml-1" href="#" @click="completed">
          Completed
        </button>
        <button v-if="!leadQuoteOrder.deletedAt && leadQuoteOrder.leadStatusId == 9" class="btn btn-sm btn-warning ml-1" href="#" @click="archive">Archive</button>
        <button v-if="!leadQuoteOrder.deletedAt && leadQuoteOrder.leadStatusId == 12" class="btn btn-sm btn-warning ml-1" href="#" @click="notDelivered">Not Delivered</button>
        <button v-if="!leadQuoteOrder.deletedAt && leadQuoteOrder.leadStatusId == 11" class="btn btn-sm btn-warning ml-1" href="#" @click="notPickedUp">Not Picked-Up</button>
        <button v-if="!leadQuoteOrder.deletedAt" class="btn btn-sm btn-danger ml-1" href="#" @click="cancelling = true">Cancel</button>
        <button v-if="leadQuoteOrder.deletedAt" class="btn btn-sm btn-warning ml-1" href="#" @click="restore">Restore</button>
        <WhyCancelModal v-if="cancelling" :leadId="leadQuoteOrder.id" :leadTypeId="leadQuoteOrder.leadTypeId" :on-close="() => (cancelling = false)" />
        <ConfirmationModal v-if="clickedReclaim" @confirm="handleReclaim" @cancel="clickedReclaim = false" label="Are you sure you want to reclaim this quote?" />
      </div>
    </div>
    <div class="top-cards">
      <div class="grid--row-big">
        <div class="top-flex">
          <div class="card flex-4 mb-4">
            <h5 class="card-header">
              Shipper 
              <span class="ml-2 text-danger font-weight-bold" v-if="leadQuoteOrder.tags.find(o => o.name === 'VIP Customer')">VIP Customer</span>
              <span class="ml-2 text-danger font-weight-bold" v-if="leadQuoteOrder.tags.find(o => o.name === 'SCAM ALERT')">SCAM ALERT</span>
              <span class="ml-2 text-danger font-weight-bold" v-if="leadQuoteOrder.tags.find(o => o.name === 'VISIBILITY ALERT')">VISIBILITY ALERT</span>
            </h5>
            <div class="card-body">
              <div class="grid--two-column">
                <div>
                  <ContactData :contact="leadQuoteOrder.customer.contact" class="ml-2" />
                </div>
                <div>
                  <div class="d-flex align-items-start justify-content-between">
                    <div>
                      <div>
                        <b>Origin:</b> {{ leadQuoteOrder.pickupLocation.city }}, {{ leadQuoteOrder.pickupLocation.state }} {{ leadQuoteOrder.pickupLocation.zip }}<br />
                        <span class="ml-4">{{ locationTypeLabel(leadQuoteOrder.pickupLocation.locationTypeId) }} </span>
                      </div>
                      <div>
                        <b>Destination:</b> {{ leadQuoteOrder.deliveryLocation.city }}, {{ leadQuoteOrder.deliveryLocation.state }} {{ leadQuoteOrder.deliveryLocation.zip }}<br />
                        <span class="ml-4">{{ locationTypeLabel(leadQuoteOrder.deliveryLocation.locationTypeId) }}</span>
                      </div>
                    </div>
                    <i class="fas fa-map-marked-alt route" style="font-size:24px;" @click="handleRouteClicked"></i>
                  </div>

                  <RouteMapModal id="cardRouteModal" :route="routeInfo" />

                  <div v-for="(vehicle, index) in leadQuoteOrder.vehicles" :key="index">
                    <div>
                      <b>Vehicle {{ index + 1 }}</b>
                      <div class="ml-4">
                        <div>{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</div>
                        <div v-if="vehicle.vehicleType">{{ vehicle.vehicleType.name }}</div>
                        <div v-if="vehicle.vin"><b>VIN:</b> {{ vehicle.vin }}</div>
                        <div v-if="vehicle.lotNumber"><b>Lot:</b> {{ vehicle.lotNumber }}</div>
                        <div v-if="vehicle.isInoperable"><b class="text-danger">Inoperable</b></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card flex-2 mb-4">
            <h5 class="card-header">Shipping info</h5>
            <div class="card-body">
              <Pickup :first="leadQuoteOrder.moveDate" :last="leadQuoteOrder.moveDateLast" class="mb-3" />
              <LabeledData
                v-if="leadQuoteOrder.pickupDate"
                label="Pickup date"
                :value="formatDateWithType(formatDate(leadQuoteOrder.pickupDate), leadQuoteOrder.pickupDateTypeId)"
              />
              <LabeledData
                v-if="leadQuoteOrder.deliveryDate"
                label="Delivery date"
                :value="formatDateWithType(formatDate(leadQuoteOrder.deliveryDate), leadQuoteOrder.deliveryDateTypeId)"
              />
              <LabeledData v-if="transportType" label="Transport type" :value="transportType" :text-color="transportTypeColor" />
              <LabeledData v-if="leadQuoteOrder.shipperInformation" label="Shipper information" :value="leadQuoteOrder.shipperInformation" />

              <div v-if="leadQuoteOrder.followups.length" class="mt-3">
                <b>Follow-up:</b>
                <div v-for="followup in leadQuoteOrder.followups" :key="followup.id">
                  {{ followup.user && followup.user.name }} {{ followup.followupAt | formatDateTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="top-flex">
          <div class="card flex-4 mb-4" v-if="leadQuoteOrder.shipperNotes || leadQuoteOrder.carrier">
            <h5 class="card-header"><i class="fa fa-exclamation text-danger mr-2"></i> Shipper Notes</h5>
            <div class="card-body">
              <p class="pre">{{ leadQuoteOrder.shipperNotes }}</p>
            </div>
          </div>
          <div class="card flex-2 mb-4" v-if="leadQuoteOrder.carrier">
            <h5 class="card-header">Dispatched to Carrier</h5>
            <div class="card-body">
              <ContactData :carrier-id="leadQuoteOrder.carrier.id" :contact="leadQuoteOrder.carrier.contact || {}" />
            </div>
          </div>
          <div v-else class="flex-2"></div>
        </div>
      </div>
      <div class="grid--row-small">
        <div class="card mb-4">
          <h5 class="card-header">Status</h5>
          <div class="card-body">
            <div id="status" class="status">
              <div v-for="status in leadQuoteOrder.leadStatusChanges" :key="status.id">
                <LabeledData :label="status.leadStatus.title" :value="formatDateTime(status.createdAt)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4 hide">
      <h5 class="card-header">Vehicles</h5>
      <div class="card-body">
        <table class="table">
          <thead class="table-bordered">
            <tr>
              <th scope="col">Price</th>
              <th scope="col">Year</th>
              <th scope="col">Make</th>
              <th scope="col">Model</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vehicle in leadQuoteOrder.vehicles" :key="vehicle.id">
              <td>{{ vehicle.price }}</td>
              <td>{{ vehicle.year }}</td>
              <td>{{ vehicle.make }}</td>
              <td>{{ vehicle.model }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card mb-4">
      <h5 class="card-header">Pricing information</h5>
      <div class="card-body">
        <div v-if="balance">
          <div class="grid--two-column mb-4">
            <div>
              <div class="grid--two-column">
                <LabeledData label="Tariff" :value="balance.priceFinal" money />
                <LabeledData v-if="leadQuoteOrder.disableProcessingFee == 0 && isShipperInvoice" label="Processing Fee" :value="balance.ccPaymentSurcharge" money />
              </div>
              <LabeledData label="Deposit Required" :value="balance.depositTotal" money v-if="!isShipperInvoice" />
              <LabeledData label="Payments Received" :value="balance.customerPaidCompany" money :text-color="balanceColor" />
              <LabeledData v-if="leadQuoteOrder.balanceMethodId == 1" label="COD Amount" :value="balance.codcopAmount" money />
              <LabeledData v-if="leadQuoteOrder.balanceMethodId == 2" label="COP Amount" :value="balance.codcopAmount" money />
              <LabeledData label="Carrier Pay" :value="balance.carrierPay" money />
              <span v-if="isShipperInvoice && leadQuoteOrder.disableProcessingFee == 0" class="text-note">5% Processing fee is included in Tariff</span>
            </div>
            <div>
              <LabeledData v-if="leadQuoteOrder.balanceMethod" label="Balance Method" :value="leadQuoteOrder.balanceMethod.title" />
              <LabeledData
                v-if="leadQuoteOrder.balanceCodcopMethod && leadQuoteOrder.balanceMethodId != 3"
                label="COD/COP Method"
                :value="leadQuoteOrder.balanceCodcopMethod.title"
              />
              <LabeledData v-if="leadQuoteOrder.balanceTerm" label="Balance Term" :value="leadQuoteOrder.balanceTerm.title" />
              <LabeledData v-if="leadQuoteOrder.balanceTermStart" label="Balance Term Start" :value="leadQuoteOrder.balanceTermStart.title" />
              <LabeledData v-if="leadQuoteOrder.balancePaymentMethod" label="Balance Payment Method" :value="leadQuoteOrder.balancePaymentMethod.title" />
            </div>
          </div>
          <div class="grid--two-column">
            <table class="table">
              <thead class="table-bordered">
                <tr>
                  <th scope="col">We owe them</th>
                  <th scope="col">Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Carrier</td>
                  <td>{{ balance.companyOwesCarrier }}</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>{{ balance.companyOwesCustomer }}</td>
                </tr>
              </tbody>
            </table>

            <table class="table">
              <thead class="table-bordered">
                <tr>
                  <th scope="col">They owe us</th>
                  <th scope="col">Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Carrier</td>
                  <td>{{ balance.carrierOwesCompany }}</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>{{ balance.customerOwesCompany }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <LoadingIndicator v-else centered />
      </div>
    </div>
    <div class="card mb-4 hide">
      <h5 class="card-header">Route</h5>
      <div class="card-body">
        {{ leadQuoteOrder.pickupLocation.zip }}
        <b>{{ leadQuoteOrder.pickupLocation.city }}, {{ leadQuoteOrder.pickupLocation.state }}</b>
        <i class="fas fa-arrow-right"></i>
        {{ leadQuoteOrder.deliveryLocation.zip }}
        <b>{{ leadQuoteOrder.deliveryLocation.city }}, {{ leadQuoteOrder.deliveryLocation.state }}</b>
      </div>
    </div>
    <div class="grid--two-column hide">
      <div class="card mb-4">
        <h5 class="card-header">Pickup contact</h5>
        <div class="card-body">
          <ContactData :contact="leadQuoteOrder.pickupContact || {}" />
        </div>
      </div>
      <div class="card mb-4">
        <h5 class="card-header">Delivery contact</h5>
        <div class="card-body">
          <ContactData :contact="leadQuoteOrder.deliveryContact || {}" />
        </div>
      </div>
    </div>

    <b-tabs class="nav-tabs-top mb-4">
      <b-tab title="Notes" active>
        <div class="card-body">
          <NotesList :leadlike-id="leadQuoteOrder.id" :read-only="false" />
        </div>
      </b-tab>
      <b-tab title="Tasks" lazy ref="tasksTab">
        <div class="card-body">
          <TasksList
            :leadlike-id="leadQuoteOrder.id"
            @tasksDue="
              x => {
                leadQuoteOrder.tasksDue = x;
              }
            "
          />
        </div>
      </b-tab>
      <b-tab title="Emails" lazy>
        <div class="card-body">
          <EmailsList :leadlike-id="leadQuoteOrder.id" />
        </div>
      </b-tab>
      <b-tab title="SMS" lazy>
        <div class="card-body">
          <SmsList :leadlike-id="leadQuoteOrder.id" />
        </div>
      </b-tab>
      <b-tab title="Competition" lazy>
        <div class="card-body">
          <RivalsList :leadlike-id="leadQuoteOrder.id" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import LabeledData from './LabeledData';
import Pickup from './Pickup';
import LoadingIndicator from './LoadingIndicator';
import ContactData from './ContactData';
import dateTypesMixin from '@/mixins/dateTypes';
import transportTypesMixin from '@/mixins/transportTypes';
import locationTypesMixin from '@/mixins/locationTypes';
import { apiRequest } from '@/api/apiRequest';
import moment from 'moment';
import NotesList from '@/components/shared/NotesList';
import EmailsList from '@/components/shared/EmailsList';
import SmsList from '@/components/shared/SmsList';
import TasksList from '@/components/shared/TasksList';
import RivalsList from '@/components/shared/RivalsList';
import WhyCancelModal from '@/components/shared/WhyCancelModal';
import DatePickerModal from '@/components/shared/DatePickerModal';
import { ApiClient } from '@/api/ApiClient';
import permissionsMixin from '/src/mixins/permissions';
import ConfirmationModal from '@/components/shared/ConfirmationModal';
import RouteMapModal from '@/components/shared/route/RouteMapModal';
import { mapState } from 'vuex';

export default {
  name: 'LeadlikeDetails',
  components: {
    LabeledData,
    Pickup,
    ContactData,
    LoadingIndicator,
    NotesList,
    EmailsList,
    SmsList,
    TasksList,
    RivalsList,
    DatePickerModal,
    WhyCancelModal,
    ConfirmationModal,
    RouteMapModal
  },
  mixins: [dateTypesMixin, transportTypesMixin, locationTypesMixin, permissionsMixin],
  props: {
    leadQuoteOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      balance: null,
      cancelling: null,
      followupLeadId: null,
      tagRemoving: null,
      routeInfo: { id: null, origin: null, destination: null, infoHtml: null },
      reclaimSent: false,
      clickedReclaim: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    followupAt() {
      if (!this.leadQuoteOrder.followup) return null;
      return this.leadQuoteOrder.followup.followupAt;
    },
    formattedDate() {
      return moment(this.followupAt).format('MMM Do, YYYY, hh:mm A');
    },
    transportType() {
      return this.transportTypeLabel(this.leadQuoteOrder.transportTypeId);
    },
    transportTypeColor() {
      return this.leadQuoteOrder.transportTypeId == 1 ? '' : 'text-danger font-weight-bold';
    },
    balanceColor() {
      if (this.isShipperInvoice) {
        return this.balance.priceFinal == this.balance.customerPaidCompany ? 'text-success font-weight-bold' : 'text-danger font-weight-bold';
      } else {
        return this.balance.depositTotal == this.balance.customerPaidCompany ? 'text-success font-weight-bold' : 'text-danger font-weight-bold';
      }
    },
    isQuote() {
      return this.leadQuoteOrder.leadTypeId == 2;
    },
    isOrder() {
      return this.leadQuoteOrder.leadTypeId == 3;
    },
    isShipperInvoice() {
      return this.leadQuoteOrder.balanceMethod ? this.leadQuoteOrder.balanceMethod.id == 3 : false;
    },
    showHold() {
      return !this.leadQuoteOrder.deletedAt && ![4, 5, 9, 10, 11, 12].includes(this.leadQuoteOrder.leadStatusId);
    },
    showPickedUp() {
      return !this.leadQuoteOrder.deletedAt && this.leadQuoteOrder.leadStatusId == 8;
    },
    checkCCTask() {
      var isTrue = false;
      this.leadQuoteOrder.tasksDue.find(t => {
        if (t.note.includes('zip')) {
          isTrue = true;
        }
      });
      return isTrue;
    },
    checkCCFailed() {
      var isTrue = false;
      this.leadQuoteOrder.tasksDue.find(t => {
        if (t.note.includes('CC failed validation')) {
          isTrue = true;
        }
      });
      return isTrue;
    },
    canBeReclaimed() {
      return this.leadQuoteOrder.userId != this.user.id;
    },
    isReclaimRequested() {
      return this.reclaimSent || this.leadQuoteOrder.leadReclaimsCount > 0;
    },
  },
  methods: {
    handleReclaim() {
      this.reclaimSent = true;
      this.$store.dispatch('leads/reclaim', { leadId: this.leadQuoteOrder.id });
      this.clickedReclaim = false;
    },

    handleRouteClicked() {
      const { id, pickupLocation, deliveryLocation } = this.leadQuoteOrder;
      this.routeInfo = {
        id,
        origin: `${pickupLocation.city}, ${pickupLocation.state}`,
        destination: `${deliveryLocation.city}, ${deliveryLocation.state}`,
        infoHtml: `(A) ${pickupLocation.city}, ${pickupLocation.state.toUpperCase()}, ${pickupLocation.zip}<i class="mx-1 fa-lg fas fa-shipping-fast"></i>(B) ${
          deliveryLocation.city
        }, ${deliveryLocation.state.toUpperCase()}, ${deliveryLocation.zip}`,
      };
      this.$bvModal.show('cardRouteModal');
    },

    async addTag(tagId) {
      await ApiClient.leads.tags.post({ leadId: this.leadQuoteOrder.id, body: { tagId: tagId } });
    },
    async removeTag(id) {
      await ApiClient.leads.tags.delete({ leadId: this.leadQuoteOrder.id, id: id });
      this.tagRemoving = null;
    },
    moment: function () {
      return moment();
    },
    formatDateWithType(date, typeId) {
      if (date && typeId) {
        const type = this.dateTypeLabel(typeId);
        return `${date} (${type})`;
      }
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    formatDateTime(date) {
      return new Date(date).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' });
    },
    async convertToOrder() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.promote({ id, body: { leadTypeId: 3 } });
      if (response.status === 200) {
        this.$router.push({ name: 'OrderDetails', params: { id } });

        const updatedLead = response.data.data;
        this.$emit('save', updatedLead);
      }
    },
    async reflowQuote() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.reflow({ id});
      if (response.status === 200) {
        this.$router.push({ name: 'QuoteList' });
      }
    },
    
    handleFollowup(leadId, type) {
      if (type == 'custom') {
        this.followupLeadId = leadId;
        return;
      }

      let time = null;
      if (type == '15m') time = moment().add(15, 'm').toISOString();
      if (type == '1h') time = moment().add(1, 'h').toISOString();
      if (type == '1d') time = moment().add(1, 'd').toISOString();

      this.setFollowup(leadId, time);
    },
    async setFollowup(leadId, time) {
      if (time) {
        await ApiClient.leads.followup({
          id: leadId,
          body: { followupAt: time },
        });
        let humanDate = moment(time).format('MMM Do, YYYY, hh:mm A');
        this.$bvToast.toast('Follow Up set for ' + humanDate, { title: 'Info', variant: 'info', solid: true });
      } else {
        await ApiClient.leads.removeFollowup({ id: leadId });
      }
      this.followupLeadId = null;
      this.$emit('refresh');
    },
    async removeFollowup() {
      var leadId = this.leadQuoteOrder.id;
      await ApiClient.leads.removeFollowup({ id: leadId });
      this.$emit('refresh');
    },
    async cancel() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.delete({ id });
      if (response.status === 200) {
        if (this.isQuote) {
          this.$router.push({ name: 'QuotesArchived' });
        } else {
          this.$router.push({ name: 'OrdersArchived' });
        }
      }
    },
    async archive() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.archive({ id });
      if (response.status === 200) {
        this.$emit('refresh');
      }
    },
    async restore() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.restore({ id });
      if (response.status === 200) {
        this.$emit('refresh');
      }
    },
    async onHold() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.onHold({ id });
      if (response.status === 200) {
        if (this.isQuote) {
          this.$router.push({ name: 'QuotesWithStatus', params: { status: 4 } });
        } else {
          this.$router.push({ name: 'OrdersWithStatus', params: { status: 4 } });
        }
      }
    },
    async removeHold() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.removeHold({ id });
      if (response.status === 200) {
        if (this.isQuote) {
          this.$router.push({ name: 'QuotesWithStatus', params: { status: 3 } });
        } else {
          this.$router.push({ name: 'OrdersWithStatus', params: { status: 3 } });
        }
      }
    },
    async pickedUp() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.pickedUp({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'OrdersWithStatus', params: { status: 3 } });
      }
    },
    async delivered() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.delivered({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'OrdersWithStatus', params: { status: 3 } });
      }
    },
    async notDelivered() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.notDelivered({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'OrdersWithStatus', params: { status: 3 } });
      }
    },
    async completed() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.completed({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'OrdersWithStatus', params: { status: 3 } });
      }
    },
    async notPickedUp() {
      const { id } = this.leadQuoteOrder;
      const response = await ApiClient.leads.notPickedUp({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'OrdersWithStatus', params: { status: 3 } });
      }
    },
    showTasks() {
      this.$refs.tasksTab.activate();
      this.$nextTick(() => {
        // block: 'end' will scroll to top of element instead of bottom
        this.$refs.tasksTab.$el.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
      });
    },
  },
  watch: {
    leadQuoteOrder: {
      immediate: true,
      async handler({ id }) {
        const response = await apiRequest({ path: `/leads/${id}/balance` });
        this.balance = response.data.data;
      },
    },
  },
};
</script>

<style lang="scss">
.internal-status {
  .dropdown-menu {
    min-width: 18rem;
  }
}
</style>

<style lang="scss" scoped>
.pre {
  white-space: pre-wrap;
}
.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.grid--two-column-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.hide {
  display: none;
}
.text-note {
  color: #3e3e3e;
  font-weight: 500;
  font-size: 12px;
}
.top-cards {
  display: flex;
  gap: 16px;
}
.grid--row-big {
  flex: 3;
}
.grid--row-small {
  flex: 1;
}
.flex-4 {
  flex: 4;
}
.flex-2 {
  flex: 2;
}
.card {
  min-height: 280px;
}
.top-flex {
  display: flex;
  gap: 16px;
}
.assigned-text {
  font-size: 16px;
  font-weight: 600;
}
.has-sub {
  &:hover {
    .dropdown-menu {
      display: block;
    }
  }
}

.route {
  cursor: pointer;
}
</style>
