<template>
  <div>
    <div class="card mb-4">
      <h5 class="card-header">Central Dispatch</h5>
      <div class="card-body">

        <div v-if="!carrier.cdMarketplaceGuid && !carrier.cdInviteGuid">
          <b-form inline @submit.prevent="submit">
            Email: <b-form-input v-model="inviteEmail" />
            <LoadingIndicator v-if="saving" /><button class="btn btn-primary ml-3">Send Invite</button>
          </b-form>
        </div>

        <div v-if="!carrier.cdMarketplaceGuid && carrier.cdInviteGuid">
          <LoadingIndicator v-if="saving" />
          <b-form inline @submit.prevent="resendInvite">
            <button class="btn btn-primary ml-3">Resend Invite</button>
          </b-form><br /><br />
          <b-form inline @submit.prevent="revokeInvite">
            <button class="btn btn-primary ml-3">Revoke Invite</button>
          </b-form>
        </div>

        <div v-if="carrier.cdGuid && carrier.cdMarketplaceGuid">
          <LoadingIndicator v-if="saving" />
          <b-form inline @submit.prevent="activate">
            <button class="btn btn-primary ml-3">Activate</button>
          </b-form><br /><br />
          <b-form inline @submit.prevent="deactivate">
            <button class="btn btn-primary ml-3">Deactivate</button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  name: 'CarrierAttachments',
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      inviteEmail: null,
      loading: false,
      saving: false,
      deleting: false,
    };
  },
  methods: {

    async submit(e) {
      e.preventDefault();
      this.sendCarrierInvite();
    },
    async activate(e) {
      e.preventDefault();
      this.saving = true;
      await ApiClient.carriers.cdActivate({ carrierId: this.carrier.id });
      this.saving = false;
    },
    async deactivate(e) {
      e.preventDefault();
      this.saving = true;
      await ApiClient.carriers.cdDeactivate({ carrierId: this.carrier.id });
      this.saving = false;
    },
    async revokeInvite(e) {
      e.preventDefault();
      this.saving = true;
      await ApiClient.carriers.cdRevokeInvite({ carrierId: this.carrier.id });
      this.saving = false;
    },

    async resendInvite(e) {
      e.preventDefault();
      this.saving = true;
      await ApiClient.carriers.cdResendInvite({ carrierId: this.carrier.id });
      this.saving = false;
    },

    async sendCarrierInvite() {
      this.saving = true;
      await ApiClient.carriers.cdInvite({ carrierId: this.carrier.id, body: {email: this.inviteEmail } });
      this.saving = false;
    },
    async deleteAttachment(attachment) {
      this.deleting = true;
      await ApiClient.carriers.attachments.delete({
        carrierId: this.carrier.id,
        id: attachment.id,
      });
      this.deleting = false;
      this.attachments = this.attachments.filter(x => x.id != attachment.id);
    },
    async fetchAttachment(attachment) {
      let response = await ApiClient.carriers.attachments.get({
        carrierId: this.carrier.id,
        id: attachment.id,
        blobRespone: true,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.filename);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
    },
    async showAttachment(attachment) {
      const link = document.createElement('a');
      link.href = attachment.publicUrl;
      link.setAttribute('target', '_blank');
      link.click();
    },
  },
 
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.img {
  max-width: 100%;
}
</style>
