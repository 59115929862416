import LayoutCentered from '@/layout/LayoutCentered';
import CarrierList from '@/components/pages/carriers/CarrierList';
import Carrier from '@/components/pages/carriers/Carrier';
import CarrierNew from '@/components/pages/carriers/CarrierNew';
import CarrierEdit from '@/components/pages/carriers/CarrierEdit';
import CarrierDetails from '@/components/pages/carriers/CarrierDetails';
import CarrierHistory from '@/components/pages/carriers/CarrierHistory';
import CarrierOrders from '@/components/pages/carriers/CarrierOrders';
import CarrierLogs from '@/components/pages/carriers/CarrierLogs';
import CarrierAttachments from '@/components/pages/carriers/CarrierAttachments';
import CarrierConnections from '@/components/pages/carriers/CarrierConnections';

export default [
  {
    path: '/carriers',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'CarriersList',
        component: CarrierList,
      },
      { path: 'new', name: 'CarrierNew', component: CarrierNew },
      {
        path: 'view/:id',
        component: Carrier,
        children: [
          {
            path: '',
            name: 'CarrierDetails',
            component: CarrierDetails,
          },
          {
            path: 'edit',
            name: 'CarrierEdit',
            component: CarrierEdit,
          },
          {
            path: 'routes',
            name: 'CarrierRoutes',
            component: CarrierDetails,
          },
          {
            path: 'orders',
            name: 'CarrierOrders',
            component: CarrierOrders,
          },
          {
            path: 'logs',
            name: 'CarrierLogs',
            component: CarrierLogs,
          },
          {
            path: 'history',
            name: 'CarrierHistory',
            component: CarrierHistory,
          },
          {
            path: 'attachments',
            name: 'CarrierAttachments',
            component: CarrierAttachments,
          },
          {
            path: 'connections',
            name: 'CarrierConnections',
            component: CarrierConnections,
          },
        ],
      },
    ],
  },
];
