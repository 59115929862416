<template>
  <div>
    <div class="grid--three-column">
      <div v-if="carrier.contact" class="card mb-4">
        <h5 class="card-header">Contact</h5>
        <div class="card-body">
          <div class="mb-2">
            <span class="h5"><i class="fa-lg fas fa-info mr-1 ml-2"></i> Status: </span>
            <span :class="carrier.isActive ? 'text-success' : 'text-danger'">{{ carrier.isActive ? 'Active' : 'Inactive' }}</span>
          </div>
          <div class="mb-2">
            <span class="h5"><i class="fa-lg fas fa-paper-plane mr-1"></i>Can Dispatch:</span>
            <span :class="carrier.canDispatch ? 'text-success' : 'text-danger'">{{ carrier.canDispatch ? 'Active' : 'Inactive' }}</span>
          </div>
          <div class="mb-2">
            <b-rating no-border disabled :value="carrier.rating" class="col-xl-6 col-12" />
          </div>
          <LabeledData label="Company" :value="carrier.contact.company" hide-if-blank />
          <LabeledData label="Contact" :value="carrier.contact.contact" hide-if-blank />
          <LabeledData label="Phone" :value="carrier.contact.phone | formatPhone" hide-if-blank />
          <LabeledData label="Phone 2" :value="carrier.contact.phone2 | formatPhone" hide-if-blank />
          <LabeledData label="Email" :value="carrier.contact.email" hide-if-blank />
          <LabeledData label="Cell phone" :value="carrier.contact.cellPhone | formatPhone" hide-if-blank />
          <LabeledData label="Fax" :value="carrier.contact.fax | formatPhone" hide-if-blank />
          <LabeledData label="Tax Id Num" :value="carrier.taxIdNum" hide-if-blank />
          <LabeledData label="MC Number" :value="carrier.mcNumber" hide-if-blank />
          <LabeledData label="USDOT Number" :value="carrier.usdot" hide-if-blank />
          <LabeledData label="Central Dispatch user" :value="carrier.cdGuid ? 'Yes': 'No'" hide-if-blank />
          <LabeledData label="Private Marketplace user" :value="carrier.cdMarketplaceGuid ? 'Yes': 'No'" hide-if-blank />
        </div>
      </div>
      <div v-if="carrier.location" class="card mb-4">
        <h5 class="card-header">Address</h5>
        <div class="card-body">
          <LabeledData label="Location" :value="carrier.location.city + ', ' + carrier.location.state + ' ' + carrier.location.zip" hide-if-blank />
          <LabeledData label="Address" :value="carrier.location.address" hide-if-blank />
          <LabeledData label="Address2" :value="carrier.location.address2" hide-if-blank />
          <div class="mt-3">
            <span class="h5"><i class="fa-lg fas fa-comments mr-1"></i> Notes:</span>
            <div class="pre">{{ carrier.notes }}</div>
          </div>
        </div>
      </div>
      <div v-if="carrier.insurance" class="card mb-4">
        <h5 class="card-header">Insurance</h5>
        <div class="card-body">
          <LabeledData label="Company" :value="carrier.insurance.company" />
          <LabeledData label="Address" :value="carrier.insurance.address" hide-if-blank />
          <LabeledData label="Phone" :value="carrier.insurance.phone" hide-if-blank />
          <LabeledData label="Email" :value="carrier.insurance.email" hide-if-blank />
          <LabeledData label="Agent name" :value="carrier.insurance.agentName" hide-if-blank />
          <LabeledData label="Agent phone" :value="carrier.insurance.agentPhone" hide-if-blank />
          <LabeledData label="Policy number" :value="carrier.insurance.policyNumber" />
          <LabeledData label="Policy expires on" :value="carrier.insurance.expirationDate | formatDate" />
          <LabeledData label="Certificate Holder" :value="carrier.insurance.isCertificateHolder ? 'Yes' : 'No'" />
          <LabeledData label="Additionally Insured" :value="carrier.insurance.isExtraInsured ? 'Yes' : 'No'" />
          <LabeledData label="Broke/Carrier Contract" :value="carrier.insurance.isBrokerContract ? 'Yes' : 'No'" />
        </div>
      </div>
    </div>
    <div v-if="carrier.leads.length" class="card mb-4">
      <h5 class="card-header">Active Orders</h5>
      <div class="card-body">
        <table class="table">
          <thead class="table">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Pickup Date</th>
              <th scope="col">Customer</th>
              <th scope="col">Vehicles</th>
              <th scope="col">Route</th>
              <th scope="col">Carrier Pay</th>
              <th scope="col">User</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lqo in carrier.leads" :key="lqo.id">
              <td class="px-1">
                <router-link :to="{ name: 'OrderDetails', params: { id: lqo.id } }">#{{ lqo.id }}</router-link>
              </td>
              <td>{{ lqo.pickupDate | formatDate }}</td>
              <td>
                <div v-if="lqo.customer.contact.firstName || lqo.customer.contact.lastName">{{ lqo.customer.contact.firstName }} {{ lqo.customer.contact.lastName }}</div>
                <div v-if="lqo.customer.contact.company">{{ lqo.customer.contact.company }}</div>
              </td>
              <td>
                <div v-for="vehicle in lqo.vehicles" :key="vehicle.id">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</div>
              </td>
              <td>
                <LeadlikeTableLocation :location="lqo.pickupLocation" />
                <span class="mr-1 fas fa-arrow-right"></span>
                <LeadlikeTableLocation :location="lqo.deliveryLocation" />
              </td>
              <td>{{ lqo.carrierPay }}</td>
              <td>{{ lqo.user ? lqo.user.name : '-' }}</td>
              <td>{{ lqo.leadStatus.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import LabeledData from '@/components/shared/LabeledData';
import LeadlikeTableLocation from '@/components/shared/LeadlikeTableLocation';

export default {
  name: 'CarrierDetails',
  components: { LabeledData, LeadlikeTableLocation },
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.pre {
  white-space: pre-wrap;
}
</style>
